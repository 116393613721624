import createDataContext from "./createContext";
import { WebTrakingService,BaseRedirect } from "../api";
import moment from "moment";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";
import { sendDialogCustuom } from "../hooks/useDialogCustuom";
import { notificationMessage } from "../hooks/useNotification";


const TRACKING_LIST = "tracking_list";
const COLUMNS_CUSTOM = "columns_custom";
const COLUMNS_DETAIL = "columns_detail";
const COLUMNS_CORRISPONDENTE = "columns_detail";
const IMG_BASE64 = "img_base64";
const TRACKING_DETAIL = "tracking_detail";
const STEPPER = "stepper";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";
const SPEDIZIONE_DA_INVIARE = "spedizione_da_inviare";
const CONFIG_SPED = "config_sped";
const ERROR_MESSAGGE = "error_messagge";
const CONFIG_PRINT = "config_Print";
const SPEDIZIONI_LIST = "spedizioni_list";
const SPEDIONI_DETAIL = "spedizioni_detail";
const SPEDIONI_DETAIL_EDIT = "spedizioni_detail_edit";
const COLUMNS_CUSTOM_SPED = "columns_custom_sped";
const SPEDIZIONI_NO_DISTINTA = "spedizioni_no_distinta";
const SPEDIZIONI_NO_DISTINTA_LIST = "spedizioni_no_distinta_list";
const SPEDIZIONI_NO_DISTINTA_DATA = "spedizioni_no_distinta_data";
const COMPILA_SPEDIZIONE_AUTO = "compila_spedizione_auto";
const COMPILA_SERVIZIO_AUTO = "compila_servizio_auto";
const FILTRI_STATO_TRACKING = "filtri_stato_tracking";
const FILTRI_STATO_SPEDIZIONI = "filtri_stato_tracking";
const INDIRIZZICOD_MITTENTE_DEFAULT = "indirizzicod_mittente_default";
const INDIRIZZICOD_DESTINATARIO_DEFAULT = "indirizzicod_destinatario_default";
const DOWNLOADPOD_DETAIL = "downloadpod_detail";
const PRG_SPED_DETAIL = "prg_sped_detail";



const INITIAL_STATE = {
  trackingList: [],
  spedizioniList: [],
  columnsCustom: [],
  columnsCustomDetail: [],
  image: null,
  stepper: [],
  configSped: [],
  fileName: "",
  spedDetail: [
    {
      data: [],
      columns: [],
      displaytext: "",
    },
  ],
  trackingDetail: [
    {
      data: [],
      columns: [],
      displaytext: "",
    },
  ],
  isReminder: false,
  recordCount: 0,
  errorMessage2: "",
  filter: {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
    spedizioneDaInviare: [],
  },
  resettaFiltri: false,
  ricercaFiltri: false,
  configPrint: [],
  columnsCustomSped: [],
  azioni: [],
  spedDetailEdit: {},
  countNoDistinta: 0,
  dataNoDistinta :{},
  spedizioniNoDistintaList:[],
  formAuto : {},  
  servizioAuto : "",

  testDoc:{
    base64:"data:text/plain;base64,RGF0YVByb250YTtkYXRhIGNvbnNlZ25hO25vbWU7Y2FwO2luZGlyaXp6bztyaWZlcmltZW50bztsb2NhbGl0YTtuYXppb25lO3BlcnNvbmE7ZW1haWw7cHJvdmluY2lhO3RlbGVmb25vO2NvbGxpO3Blc287dm9sdW1lO2VwYWw7bm90YTt2YWxvcmUgY29udHJhc3NlZ25vO3ZhbHV0YSBjb250cmFzc2Vnbm87dGlwbyBpbmNhc3NvO3ZhbG9yZSBtZXJjZTt2YWx1dGEgdmFsb3JlIG1lcmNlDQoyMDIzLTA1LTI5OzIwMjMtMDYtMDU7U0VUVEVDQVNFIFNQQTs0MDEwMDsgQ09SU08gSVRBTElBLCAzOzEyMzQ1MDAxO1RyaWVzdGU7SVQ7Um9tb2xvO3NldHRlY2FzZUBwcm92YS5jb207VFM7MDQwMTIzNHh4eDsxMDsxMDsxMDsxO25vdGEgY29uc2VnbmE7MTAwO0VVUjtBQzszMDA7RVVSDQoyMDIzLTA1LTI5OzIwMjMtMDYtMDU7VElaSU8gU1JMOzQwMTAwOyBDT1JTTyBJVEFMSUEsIDU7MTIzNDUwMDI7VHJpZXN0ZTtJVDtGcmFuY2VzY287dGl6aW9AcHJvdmEuY29tO1RTOzA0MDEyMzR4eHg7MjA7MjA7MjA7MTtub3RhIGNvbnNlZ25hOzE1MDtFVVI7QUM7MzUwO0VVUg0K",
    filename:"ProvaSpedizioni.csv"  ,
    mimeType:"data:text/plain;base64,"
  },
  chiediConfermaDownloadPOD : false,
  titoloConfermaDownloadPOD : "",
  testoConfermaDownloadPOD : "",
  creaFileTrackingActive : false,
  filtriStatoTracking : [],
  filtriStatoSpeidizioni : [],
  MittenteDefault : {},
  DestinatarioDefault : {},
  DownloadPodDetail : {},
  PrgSpedizione : 0
};

const trackingReducer = (state, action) => {
  switch (action.type) {
    case TRACKING_LIST: {
      const { data, countRecord, azioniTrak, confermaPOD, testoPOD, titoloPOD,CreaFileTracking } = action.payload;
      return {
        ...state,
        trackingList: data,
        recordCount: countRecord,
        resettaFiltri: false,
        azioni: azioniTrak,
        chiediConfermaDownloadPOD:confermaPOD,
        titoloConfermaDownloadPOD: titoloPOD,
        testoConfermaDownloadPOD: testoPOD,
        creaFileTrackingActive : CreaFileTracking,
      };
    }
    case STEPPER: {
      return { ...state, stepper: action.payload };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case COLUMNS_CUSTOM_SPED: {
      return { ...state, columnsCustomSped: action.payload };
    }
    case COLUMNS_DETAIL: {
      return { ...state, columnsCustomDetail: action.payload };
    }
    case COLUMNS_CORRISPONDENTE: {
      return { ...state, columnsCustomDetail: action.payload };
    }
    case IMG_BASE64: {
      const { base64, fileName } = action.payload;
      return { ...state, image: base64, fileName: fileName };
    }
    case TRACKING_DETAIL: {
      return { ...state, trackingDetail: action.payload };
    }
    case SPEDIONI_DETAIL: {
      return { ...state, spedDetail: action.payload };
    }
    case SPEDIONI_DETAIL_EDIT: {
      
      return { ...state, spedDetailEdit: action.payload };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter, resettaFiltri: true };
    }
    case SPEDIZIONE_DA_INVIARE: {
      return { ...state, spedizioneDaInviare: action.payload };
    }
    case SPEDIZIONI_LIST: {
      const { data, countRecord, remider, azioniSped } = action.payload;
      return {
        ...state,
        spedizioniList: data,
        recordCount: countRecord,
        resettaFiltri: false,
        isReminder: remider,
        azioni: azioniSped,
      };
    }
    case SPEDIZIONI_NO_DISTINTA: {
      const Value = action.payload;
      return { ...state, countNoDistinta: Value };
    }
    case SPEDIZIONI_NO_DISTINTA_DATA: {
      return { ...state, dataNoDistinta:action.payload };
    }
    case SPEDIZIONI_NO_DISTINTA_LIST: {
      const { data, countRecord, remider, azioniSped } = action.payload;
      return {
        ...state,
        spedizioniNoDistintaList: data,
        recordCount: countRecord,
        resettaFiltri: false,
        isReminder: remider,
        azioni: azioniSped,
      };
    }
    case CONFIG_SPED: {
      return { ...state, configSped: action.payload };
    }
    case ERROR_MESSAGGE: {
      return { ...state, errorMessage2: action.payload };
    }
    case CONFIG_PRINT: {
      return { ...state, configPrint: action.payload };
    }
    case COMPILA_SPEDIZIONE_AUTO : {
      return { ...state, formAuto : action.payload };
    }
    case COMPILA_SERVIZIO_AUTO : {
      return { ...state, servizioAuto : action.payload };
    }
    case FILTRI_STATO_TRACKING : {
      return { ...state, filtriStatoTracking : action.payload };
    }
    case FILTRI_STATO_SPEDIZIONI : {
      return { ...state, filtriStatoSpeidizioni : action.payload };
    }
    case INDIRIZZICOD_DESTINATARIO_DEFAULT: {
      return { ...state, DestinatarioDefault: action.payload };
    }
    case INDIRIZZICOD_MITTENTE_DEFAULT: {
      return { ...state, MittenteDefault: action.payload };
    }

    case DOWNLOADPOD_DETAIL: {
      return { ...state, DownloadPodDetail: action.payload };
    }
    case PRG_SPED_DETAIL: {
      return { ...state, PrgSpedizione: action.payload };
    }
    default: {
      return state;
    }
  }
};

const getTrackingList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking
  ) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/websearch?limit=${limit}&offset=${offset}&riferimento=${
        filterTracking ? filterTracking.Riferimento : ""
      }&searchall=${
        filterTracking ? filterTracking.searchall : ""
      }&datainiziale=${
        filterTracking === undefined || filterTracking?.DataIniziale === ""
          ? ""
          : moment(filterTracking?.DataIniziale).format("YYYY-MM-DD")
      }&datafinale=${
        filterTracking === undefined || filterTracking?.DataFinale === ""
          ? ""
          : moment(filterTracking?.DataFinale).format("YYYY-MM-DD")
      }&destprovincia=${
        filterTracking ? filterTracking.DestProvincia : ""
      }&destlocalita=${
        filterTracking ? filterTracking.DestLocalita : ""
      }&destragsoc=${
        filterTracking ? filterTracking.DestRagSoc : ""
      }&destnazione=${
        filterTracking ? filterTracking.DestNazione : ""
      }&statoweb=${filterTracking ? filterTracking.StatoWeb : ""}`
    );
    if (response.data) {
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: TRACKING_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
            azioniTrak: response.data.azioni,
            confermaPOD:response.data.ChiediConfermaDownloadPOD,
            testoPOD:response.data.TestoConfermaDownloadPOD,
            titoloPOD:response.data.TitoloConfermaDownloadPOD,
            CreaFileTracking:response.data.CreaFileTrackingActive,

          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: TRACKING_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
            azioniTrak: response.data.azioni,
            confermaPOD:response.data.ChiediConfermaDownloadPOD,
            testoPOD:response.data.TestoConfermaDownloadPOD,
            titoloPOD:response.data.TitoloConfermaDownloadPOD,
            CreaFileTracking:response.data.CreaFileTrackingActive,
            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }
    let col = [
    /*  {
        field: "checkbox",
        headerName: "",
        //flex: 1,
      }, */
      {
        field: "action",
        headerName: "Azioni",
        //flex: 1,
      },
    ];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
  };

  const getCorrispondenteList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking
  ) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/websearch?limit=${limit}&offset=${offset}&riferimento=${
        filterTracking ? filterTracking.Riferimento : ""
      }&searchall=${
        filterTracking ? filterTracking.searchall : ""
      }&datainiziale=${
        filterTracking === undefined || filterTracking?.DataIniziale === ""
          ? ""
          : moment(filterTracking?.DataIniziale).format("YYYY-MM-DD")
      }&datafinale=${
        filterTracking === undefined || filterTracking?.DataFinale === ""
          ? ""
          : moment(filterTracking?.DataFinale).format("YYYY-MM-DD")
      }&destprovincia=${
        filterTracking ? filterTracking.DestProvincia : ""
      }&destlocalita=${
        filterTracking ? filterTracking.DestLocalita : ""
      }&destragsoc=${
        filterTracking ? filterTracking.DestRagSoc : ""
      }&destnazione=${
        filterTracking ? filterTracking.DestNazione : ""
      }&statoweb=${filterTracking ? filterTracking.StatoWeb : ""}`
    );
    if (response.data) {
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: TRACKING_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
            azioniTrak: response.data.azioni,
            chiediConfermaDownloadPOD:response.data.ChiediConfermaDownloadPOD,
            testoPOD:response.data.TestoConfermaDownloadPOD,
            titoloPOD:response.data.TitoloConfermaDownloadPOD,
            CreaFileTracking:response.data.CreaFileTrackingActive,

          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: TRACKING_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
            azioniTrak: response.data.azioni,
            chiediConfermaDownloadPOD:response.data.ChiediConfermaDownloadPOD,
            testoPOD:response.data.TestoConfermaDownloadPOD,
            titoloPOD:response.data.TitoloConfermaDownloadPOD,
            CreaFileTracking:response.data.CreaFileTrackingActive,

            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }
    let col = [
      
      {
        field: "checkbox",
        headerName: "",
        //flex: 1,
      }, 
      {
        field: "action",
        headerName: "Azioni",
        //flex: 1,
      },
    ];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
  };



const downloadPOD = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/pod/${PrgInterno}`
  );
  if (response.data.imagebase64) {
    let image = response.data.imagebase64 !== undefined ? response.data?.imagebase64 : null
    dispatch({
      type: IMG_BASE64,
      payload: { fileName: response.data.filename, base64: image },
    });
  }else{
    dispatch({
      type: IMG_BASE64,
      payload: { fileName: "Non ci sono file", base64: false },
    });
  }
};

const getTrackingDetail = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/viewtracking/${PrgInterno}`
  );
  if (response.data) {
    let col = [];
    let filter = response.data.data.filter(
      (res) => res.Lat !== "" && res.Lon !== ""
    );
    if (filter.length > 0) {
      col = [
        /*  {
          field: "action",
          headerName: "Azioni",
          //flex: 1,
        }, */
      ];
    }
    response.data.columns.forEach((data) => {
      if (data.id !== "Lat" && data.id !== "Lon") {
        col = [
          ...col,
          {
            field: data.id,
            headerName: data.label,
            minWidth: data.minWidth,
          },
        ];
      }
    });
    dispatch({ type: STEPPER, payload: response.data.displaystatus });
    dispatch({ type: COLUMNS_DETAIL, payload: col });
    dispatch({ type: TRACKING_DETAIL, payload: response.data });
    dispatch({ type: PRG_SPED_DETAIL, payload: response.data.PrgSpedizione });
    //04/07/2024 Greg: Per speed, attivare download pod e packing list
    dispatch({ type: DOWNLOADPOD_DETAIL, payload: response.data.DownloadPod });

  }
};

const getInfoTrackingSearch =
  (dispatch) => async (codeSearch, navigate) => {

    // per consentire carattere "/" serve doppio encoding.
    //la codifica url per il caratter è %252f, altrimenti è parte del'URI
    //lo codifico in %2F e poi uso encodeUriComponent, altrimenti non lo codifica correttamente.
    while(codeSearch.includes("/")){
      codeSearch = codeSearch.replace("/","%2F");
    };

    const response = await BaseRedirect.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server   
      }/public/spedizioni/websearchsingle/${encodeURIComponent(  codeSearch)}`
    );
    if (response.data) {
      let col = [];
      response.data.columns.forEach((data) => {
        if (data.id !== "Lat" && data.id !== "Lon") {
          col = [
            ...col,
            {
              field: data.id,
              headerName: data.label,
              minWidth: 100,
              sortable: false,
              //flex:1
            },
          ];
        }
      });
      dispatch({ type: STEPPER, payload: response.data.displaystatus });
      dispatch({ type: COLUMNS_CUSTOM, payload: col });
      dispatch({ type: TRACKING_DETAIL, payload: response.data });
      navigate(`/public/view/tracking/${codeSearch}`)
    }
    else{
      const response = await BaseRedirect.get(
        `${
          JSON.parse(localStorage.getItem("configurazioni")).server   
        }/public/ordini/websearchsingle/${encodeURIComponent(  codeSearch)}`
      );

      if (response.data) {
        let col = [];
        response.data.columns.forEach((data) => {
          if (data.id !== "Lat" && data.id !== "Lon") {
            col = [
              ...col,
              {
                field: data.id,
                headerName: data.label,
                minWidth: 100,
                sortable: false,
                //flex:1
              },
            ];
          }
        });
        dispatch({ type: STEPPER, payload: response.data.displaystatus });
        dispatch({ type: COLUMNS_CUSTOM, payload: col });
        dispatch({ type: TRACKING_DETAIL, payload: response.data });
        navigate(`/public/view/tracking/${codeSearch}`)
      }
      else{
        sendNotification(
          <NotificationContent service={"Attenzione!"} />,
          "Nessuna corrispondenza trovata!",
          "error",
          5
        );
        
      }
    }


    
  };

const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getTrackingList(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
  });
  getSpedizioniList(dispatch)(
    "",
    100,
    0,
    false,
    [],
    0,
    false,
    {
      searchall: "",
      DataIniziale: "",
      DataFinale: "",
      Riferimento: "",
      DestProvincia: "",
      DestLocalita: "",
      DestRagSoc: "",
      DestNazione: "",
      StatoWeb: "",
    },
    false
  );
};

const popolaArraySpedizione = (dispatch) => async (payload) => {
  dispatch({ type: SPEDIZIONE_DA_INVIARE, payload: payload });
};

const getConfigModeSpedizioni = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/config`
  );
  if (response) {
    console.log(response)
    dispatch({ type: CONFIG_SPED, payload: response.data.data.spedizioni });
    dispatch({type: FILTRI_STATO_SPEDIZIONI, payload :response.data.data.spedizioni.filtroStato})
    dispatch({type:INDIRIZZICOD_MITTENTE_DEFAULT, payload : response.data.data.DefaultMittente})
    dispatch({type:INDIRIZZICOD_DESTINATARIO_DEFAULT, payload : response.data.data.DefaultDestinatario})
  }

};

const getSpedizioniList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking,
    remider,
  ) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/websearch?limit=${limit}&offset=${offset}&riferimento=${
        filterTracking ? filterTracking.Riferimento : ""
      }&searchall=${
        filterTracking ? filterTracking.searchall : ""
      }&datainiziale=${
        filterTracking === undefined || filterTracking?.DataIniziale === ""
          ? ""
          : moment(filterTracking?.DataIniziale).format("YYYY-MM-DD")
      }&datafinale=${
        filterTracking === undefined || filterTracking?.DataFinale === ""
          ? ""
          : moment(filterTracking?.DataFinale).format("YYYY-MM-DD")
      }&destprovincia=${
        filterTracking ? filterTracking.DestProvincia : ""
      }&destlocalita=${
        filterTracking ? filterTracking.DestLocalita : ""
      }&destragsoc=${
        filterTracking ? filterTracking.DestRagSoc : ""
      }&destnazione=${
        filterTracking ? filterTracking.DestNazione : ""
      }&statoweb=${filterTracking ? filterTracking.StatoWeb : ""}`
    );
    
    if (response.data) {
      console.log(response)
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: SPEDIZIONI_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
            remider: remider,
            azioniSped: response.data.azioni,
          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: SPEDIZIONI_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
            remider: remider,
            azioniSped: response.data.azioni,
            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }
    let col = [
      {
        field: "checkbox",
        headerName: "",
        //flex: 1,
      },
      {
        field: "action",
        headerName: "Azioni",
        //flex: 1,
      },
    ];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM_SPED, payload: col });
  };

const getPrintFile = (dispatch) => async (PrgInterno, idModStampa) => {
  if (idModStampa !== "null") {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/print?codicedoc=${idModStampa}&prgspedizioni=${PrgInterno}`
    );
    if (response.data) {
      let image = response.data.imagebase64;
      dispatch({
        type: IMG_BASE64,
        payload: { fileName: response.data.filename, base64: image },
      });
    }
  }
};

const getPrintConfig = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/print/config`
  );
  if (response.data) {
    dispatch({
      type: CONFIG_PRINT,
      payload: response.data.data.docs,
    });
  }
};

const postSped = (dispatch) => async (sped, handleCloseDialog, navigate,setSalvataggioInCorso) => {
  setSalvataggioInCorso(true);
  const response = await WebTrakingService.post(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/spedizioni`,
    sped
  );
  if (response) {
    /* mettere il controllo del messaggio */
    if (response.data.Success) {
      handleCloseDialog();
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        false,
      );
      sendDialogCustuom(
        <NotificationContent service={"Promemoria!"} />,
        "Ricordati di inserire il ritiro per la spedizione appena creata",
        "error",
        100,
        navigate,
        "/ritiri/new",
        "inserisci ritiro"
      );
      sendNotification(
        <NotificationContent service={"Spedizione creata!"} />,
        response.data.Message,
        "success",
        5
      );
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5,
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
  setSalvataggioInCorso(false);
};

const putSped = (dispatch) => async (sped, handleCloseDialog, navigate, setSalvataggioInCorso) => {
  setSalvataggioInCorso(true);

  const response = await WebTrakingService.put(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/${sped.PrgInterno}`,
    sped
  );
  if (response) {
    /* mettere il controllo del messaggio */
    if (response.data.Success) {
      handleCloseDialog();
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      sendNotification(
        <NotificationContent service={"Spedizione modificata!"} />,
        "Spedizione modificata con successo",
        "success",
        5
      );
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        true
      );
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
  setSalvataggioInCorso(false);

};

const delSped = (dispatch) => async (PrgInterno, navigate) => {
  const response = await WebTrakingService.delete(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/${PrgInterno}`
  );
  if (response) {
    if (response.data.Success) {
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      sendNotification(
        <NotificationContent service={"Spedizione eliminata!"} />,
        "Spedizione eliminata con successo",
        "success",
        5
      );
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        false
      );
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
};

const errorCancel2 = (dispatch) => async () => {
  dispatch({ type: ERROR_MESSAGGE, payload: "" });
};

const getSpedizioneDetail = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/view/${PrgInterno}`
  );
  if (response.data) {
    let col = [];
    response.data.columns.forEach((data) => {
      if (data.id !== "Lat" && data.id !== "Lon") {
        col = [
          ...col,
          {
            field: data.id,
            headerName: data.label,
            minWidth: 100,
            sortable: false,
            //flex:1
          },
        ];
      }
    });
    dispatch({ type: STEPPER, payload: response.data.displaystatus });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
    dispatch({ type: SPEDIONI_DETAIL, payload: response.data });
    dispatch({ type: DOWNLOADPOD_DETAIL, payload: response.data.DownloadPod });

  }
};

const getEditSpedizione = (dispatch) => async (prgSped) => {
  dispatch({ type: SPEDIONI_DETAIL_EDIT, payload: {} });
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/${prgSped}?action=edit`
  );
  if (response.data) {
    dispatch({ type: SPEDIONI_DETAIL_EDIT, payload: response.data.data });
  }
};

const postConsegnato =
  (dispatch) =>
  async (data, navigate, setCheckBoxSelectionPrint, setReload) => {
    sendNotification(
           <NotificationContent service={"Consegna confermata!"} />,
           "",
           "success",
           5
         );
    // setReload(true);
    // setTimeout(() => setReload(false), 1000);
    // setCheckBoxSelectionPrint([]);
    // let prgSpedizioni = "";
    // data.forEach((element) => {
    //   prgSpedizioni = prgSpedizioni + element.PrgInterno + ",";
    // });
    // const response = await WebTrakingService.post(
    //   `${
    //     JSON.parse(localStorage.getItem("configurazioni")).server
    //   }/spedizioni/spedizioniweb/creadistinta`,
    //   { prgspedizioni: prgSpedizioni }
    // );
    // if (response.data) {
    //   sendNotification(
    //     <NotificationContent service={"Distinta creata!"} />,
    //     response.data.Message,
    //     "success",
    //     5
    //   );
    //   getSpedizioniList(dispatch)(
    //     "",
    //     100,
    //     0,
    //     false,
    //     [],
    //     0,
    //     false,
    //     {
    //       searchall: "",
    //       DataIniziale: "",
    //       DataFinale: "",
    //       Riferimento: "",
    //       DestProvincia: "",
    //       DestLocalita: "",
    //       DestRagSoc: "",
    //       DestNazione: "",
    //       StatoWeb: "",
    //     },
    //     false
    //   );
    // }
  };

const postCreaDistinta =
  (dispatch) =>
  async (data, navigate, setCheckBoxSelectionPrint, setReload, CreaOrdine) => {
    setReload(true);
    setTimeout(() => setReload(false), 1000);
    setCheckBoxSelectionPrint([]);
    let prgSpedizioni = "";
    data.forEach((element) => {
      prgSpedizioni = prgSpedizioni + element.PrgInterno + ",";
    });
    const response = await WebTrakingService.post(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/creadistinta`,
      { prgspedizioni: prgSpedizioni }
    );
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Distinta creata!"} />,
        response.data.Message,
        "success",
        5
      );
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        false
      );
    }

  };

const pulisciSpedDetail = (dispatch) => async () => {
  dispatch({ type: SPEDIONI_DETAIL, payload: {} });
  dispatch({ type: SPEDIONI_DETAIL_EDIT, payload: {} });
};

const spedizioniEmail = (dispatch) => async (prgSped) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/mailto/${prgSped}?type=info`
  );
  if (response.data) {
    window.location = response.data.mailto;
    //dispatch({ type: SPEDIONI_DETAIL, payload: response.data });
  }
};

const trackEmail = (dispatch) => async (prgTrak) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/mailto/${prgTrak}?type=tracking`
  );
  if (response.data) {
    window.location = response.data.mailto;
    //dispatch({ type: SPEDIONI_DETAIL, payload: response.data });
  }
};


const getCountSenzaDistinta =
  (dispatch) => async () => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/dashboard/count?daterange=currmonth&prgdistinta=0`
    );
    if (response.data) {
      dispatch({ type: SPEDIZIONI_NO_DISTINTA, payload: response.data.Value });  
      dispatch({ type: SPEDIZIONI_NO_DISTINTA_DATA, payload: response.data });    
    }
    
  };

  const getSpedizioniSenzaDistinta =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    remider,
    startDate,
    endDate
  ) => {
    startDate = startDate.replace("/", "-").replace("/", "-").replace("/", "-")
    endDate = endDate.replace("/", "-").replace("/", "-").replace("/", "-")
    
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/websearch?limit=${limit}&offset=${offset}&riferimento=&searchall=${""
      }&datainiziale=${moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      }&datafinale=${moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      }&destprovincia=&destlocalita=&destragsoc=&destnazione=&statoweb=INS`
    );

    if (response.data) {
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: SPEDIZIONI_NO_DISTINTA_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
            remider: remider,
            azioniSped: response.data.azioni,
          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: SPEDIZIONI_NO_DISTINTA_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
            remider: remider,
            azioniSped: response.data.azioni,
            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }

  };

const resetNoDistintaData =
  (dispatch) => async () => {
      dispatch({ type: SPEDIZIONI_NO_DISTINTA, payload: 0 });  
      dispatch({ type: SPEDIZIONI_NO_DISTINTA_DATA, payload: {} });      
  }; 

const postCreaRitiroDaSpedizioni = (dispatch) => async (prgSpedizioniList, navigate) => {
  let prgSpedizioni = "";
 
  prgSpedizioniList.forEach((element, index) => {
    if(index < prgSpedizioniList.length-1){
      prgSpedizioni = prgSpedizioni + element.PrgInterno + ",";
    }
    else {
      prgSpedizioni = prgSpedizioni + element.PrgInterno;
    }
  });

  let luogoRitiro = JSON.parse(localStorage.getItem("luogoRitiro"));
  let dataToSend = {}
  dataToSend.prgspedizioni = prgSpedizioni;
  dataToSend.MittNazione =  "IT";
  dataToSend.MittLocalita = luogoRitiro.Localita;
  dataToSend.MittProvincia = luogoRitiro.Provincia;
  dataToSend.MittCap = luogoRitiro.Cap;
  dataToSend.MittRagSoc = luogoRitiro.RagSoc;
  dataToSend.MittIndirizzo = luogoRitiro.Indirizzo;
  dataToSend.MittPersona = luogoRitiro.Persona;
  dataToSend.MittTelefono = luogoRitiro.Telefono;
  dataToSend.MittEmail = luogoRitiro.Email;

  const response = await WebTrakingService.post(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/spedizioni/crearitiro`,
    dataToSend
  );

    if (response) {

    //   /* mettere il controllo del messaggio */
      if (response.data.Success) {
        dispatch({ type: ERROR_MESSAGGE, payload: "" });
        sendNotification(
          <NotificationContent service={"OK!"} />,
          response.data.Message,
          "success",
          5
        );
      } 
      else {
        sendNotification(
          <NotificationContent service={"Errore!"} />,
          response.data.Err_Message,
          "error",
          5
        );
  //     
  //     dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
      }
    }
  };


const compilaSpedizioneAuto = (dispatch) => async ( formAuto, servizio, navigate) => {
  dispatch({ type: COMPILA_SPEDIZIONE_AUTO, payload: formAuto });
  dispatch({ type: COMPILA_SERVIZIO_AUTO, payload: servizio });
  navigate("/spedizioni/new");
}

const clearFormAuto = (dispatch) => async () => {
  dispatch({ type: COMPILA_SPEDIZIONE_AUTO, payload: {} });
  dispatch({ type: COMPILA_SERVIZIO_AUTO, payload: "" });
}

const postUploadFileSpedizioni = (dispatch) => async ( fileBase64, filename) => {
  const response = await WebTrakingService.post(`${JSON.parse(localStorage.getItem("configurazioni")).server}/spedizioni/uploadfile`, 
  JSON.stringify({imagebase64:fileBase64, filename:filename})); 
   if(response.data.Success === true){
    sendNotification(
      <NotificationContent service={"OK!"} />,
      response.data.Message,
      "success",
      5
    );
    //ricarica lista spedizioni
    getSpedizioniList(dispatch)(
      "",
      100,
      0,
      false,
      [],
      0,
      false,
      {
        searchall: "",
        DataIniziale: "",
        DataFinale: "",
        Riferimento: "",
        DestProvincia: "",
        DestLocalita: "",
        DestRagSoc: "",
        DestNazione: "",
        StatoWeb: "",
      },
      false
    );
  }
  else if(response.data.Success === false){
    sendNotification(
      <NotificationContent service={"Errore!"} />,
      response.data.Message,
      "warning",
      5
    );
  }
  else {
    sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5,
      );
    }
}
  


const getTrackingConfig = (dispatch) => async ( ) => {
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/spedizioni/config`); 
  if(response.data){  
    dispatch({ type: FILTRI_STATO_TRACKING, payload : response.data.filtroStato});
  }
  
}


const postCreaFileTracking = (dispatch) => async (data, navigate, setCheckBoxSelectionPrint, setReload, CreaOrdine) => {
    const response = await WebTrakingService.post(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/creafiletracking`,
    );
    if (response.data) {
      if (response.data.Success){
        sendNotification(
          <NotificationContent service={"OK!"} />,
          response.data.Message,
          "success",
          5
        );
      }
      else {
        sendNotification(
          <NotificationContent service={"Attenzione!"} />,
          response.data.Message,
          "warning",
          5
        );
      }

    }

  };


export const { Provider, Context } = createDataContext(
  trackingReducer,
  {
    getCorrispondenteList,
    getTrackingList,
    downloadPOD,
    getTrackingDetail,
    getInfoTrackingSearch,
    aggiornaFiltri,
    resetFiltri,
    postSped,
    popolaArraySpedizione,
    getConfigModeSpedizioni,
    getSpedizioniList,
    getPrintFile,
    errorCancel2,
    getPrintConfig,
    getSpedizioneDetail,
    postCreaDistinta,
    getEditSpedizione,
    putSped,
    delSped,
    pulisciSpedDetail,
    spedizioniEmail,
    trackEmail,
    postConsegnato,
    getCountSenzaDistinta,
    getSpedizioniSenzaDistinta,
    resetNoDistintaData,
    postCreaRitiroDaSpedizioni,
    compilaSpedizioneAuto,
    clearFormAuto,
    postUploadFileSpedizioni,
    getTrackingConfig,
    postCreaFileTracking
  }, // actions
  INITIAL_STATE // initial state
);
